import {closeModal} from 'ui-utils/useModal';
import {ReactComponent as Envelope} from './images/Envelope.svg';
import {ReactComponent as Star} from './images/Star.svg';
import {css} from '@emotion/react';
import {
  Button,
  FormFieldSelect,
  FormFieldTextArea,
  Layout,
  Modal,
  Text,
  ModalContent,
  useForm,
  ButtonsGroup,
  Heading,
  ClickAway,
  extractErrorMessage,
  ModalFooter,
  Span,
  useOptionEnsure
} from 'ui-components';
import {useMutation} from '@tanstack/react-query';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store.ts';
import * as yup from 'yup';
import {apiClient} from "utils/clients";
import {useMemo} from 'react';

type FeedbackPayload = {
  topic: string,
  rate: number,
  feedbackText: string,
  userName: string,
  userEmail: string,
  company: string
}

// rating css inspired by: https://codepen.io/lionessheart/pen/ExzVRmj
const modal = css`
  width: 600px;
  footer {
    border: none;
    padding-top: 0;
  }
`;

const modalContent = css`
  padding-top: 24px;
  & > div {
    margin: 0;
  }
`;

const gridItem = css`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const starRating = css`
  width: 30px;
  height: 30px;
  stroke: var(--text-secondary);
  fill: var(--content-background);
  
  &:hover path {
    fill: var(--table-header-background);
  }

  &:hover ~ svg:not(.selected) path {
    fill: var(--table-header-background);
  } 

  &.selected {
    path {
      fill: var(--icon-primary);
      stroke: var(--icon-primary);
    }
    
    &:hover ~ svg path {
      fill: var(--icon-primary);
      stroke: var(--icon-primary);
    }
  }
`;

const useSendFeedback = ({accountId}: {accountId: string | undefined}) => {
  return useMutation({
    mutationKey: ["FEEDBACK", "MARKETING"],
    mutationFn: async (payload: FeedbackPayload) => {
      return apiClient.post(`${import.meta.env.VITE_WLM_BACKEND_URL}/accounts/${accountId}/marketing/v1/notification`, payload)
    }})
}

const validator = yup.object().shape({
  feedbackText: yup
    .string()
    .when("rate", {
      is: 0,
      then: () => yup
        .string()
        .required("Select a rating and/or enter comments to share feedback.")
    }),
  rate: yup
    .number()
    .integer()
    .min(0)
    .max(5)
});

const FeedbackModal = () => {

  const userEmail = useSelector((state: RootState) => state.auth.email);
  const company = useSelector((state: RootState) => state.auth.company);
  const userName = useSelector((state: RootState) => state.auth.name);
  const accountId = useSelector((state: RootState) => state.tenancy.selectedAccountId);
  const sendFeedback = useSendFeedback({accountId});
  const services = useSelector((state: RootState) => state.services.workloadConfigs);
  const topics = useMemo(() => {
    const servicesTopics = services
      .filter(service => (service.homepage && !service.homepage.isComingSoon && service.id != 'test-service'))
      .map(service => ({value: service.name, label: service.name}));
    return [{value: 'General', label: 'Workload Factory - General'},
      {value: 'Storage', label: 'Storage'},
      ...servicesTopics]
  }, [services]);

  const form = useForm({
    topic: null,
    rate: 0,
    feedbackText: '',
  }, validator);

  useOptionEnsure(topics, form, 'topic', undefined);

  const clickAway = () => {
    if (!sendFeedback.isSuccess) return;
    closeModal();
  }

  return <Modal css={modal}>
    <ClickAway ignoreParentClick={true} listener={clickAway} closeOnModalClick/>
    <ModalContent css={modalContent}>
      <Layout.Grid centerContent>
        <div css={gridItem} style={{marginBottom: 32}}>
          <Envelope/>
        </div>
        {!sendFeedback.isSuccess ?
          <div style={{width: '100%'}}>
            <div css={gridItem} style={{marginBottom: 16}}>
              <Heading level={3}>How satisfied are you with Workload Factory?</Heading>
            </div>
            <div css={gridItem} style={{marginBottom: 30, direction: 'rtl', cursor: 'pointer'}}>
              <Star css={starRating} className={form.formState['rate'] >= 5 ? 'selected' : ''} onClick={() => form.handleFormChange(5, {name: "rate"})}/>
              <Star css={starRating} className={form.formState['rate'] >= 4 ? 'selected' : ''} onClick={() => form.handleFormChange(4, {name: "rate"})}/>
              <Star css={starRating} className={form.formState['rate'] >= 3 ? 'selected' : ''} onClick={() => form.handleFormChange(3, {name: "rate"})}/>
              <Star css={starRating} className={form.formState['rate'] >= 2 ? 'selected' : ''} onClick={() => form.handleFormChange(2, {name: "rate"})}/>
              <Star css={starRating} className={form.formState['rate'] >= 1 ? 'selected' : ''} onClick={() => form.handleFormChange(1, {name: "rate"})}/>
            </div>
            <FormFieldSelect
              form={form}
              isSearchable={false}
              name="topic"
              label="Select a topic:"
              options={topics}
              menuPortalTarget={document.body}
              style={{marginBottom: 35}}
            />
            <FormFieldTextArea
              form={form}
              name="feedbackText"
              charCount={true}
              maxChars={500}
              inputStyle={{height: 164}}
              errorStyle={{width: 430}}
              placeholder="Write your comment here"
            />
          </div> :
          <div style={{width: '100%'}}>
            <div css={gridItem} style={{marginBottom: 16}}>
              <Heading level={3}>Thank you!</Heading>
            </div>
            <div css={gridItem} style={{marginBottom: 16}}>
              <Text center>Thanks for sharing your thoughts. We appreciate your feedback. <br/>Our response will be sent to your registration email address: <br/><Span bold>{userEmail}</Span></Text>
            </div>
          </div>
        }
      </Layout.Grid>
    </ModalContent>
    {!sendFeedback.isSuccess && <ModalFooter error={extractErrorMessage(sendFeedback.error)}>
        <ButtonsGroup>
            <Button isSubmitting={sendFeedback.isLoading} color="primary" onClick={(e: unknown) => {
              sendFeedback.reset();
              const submit = form.handleFormSubmit(() => {
                sendFeedback.mutate({...form.formState, topic: form.formState.topic.value, userName, userEmail, company})
              });
              submit(e);
            }}>
                Share
            </Button>
            <Button disabled={sendFeedback.isLoading} color="secondary" onClick={() => closeModal()}>
                Cancel
            </Button>
        </ButtonsGroup>
    </ModalFooter>}
  </Modal>
}

export default FeedbackModal;
